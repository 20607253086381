<app-header></app-header>
<div class="container">
  <div class="row">
    <aside class="sidebar col-md-3 p-0">
      <app-sidebar></app-sidebar>
    </aside>
    <main id="main-content" class="col-md-8 ms-sm-auto col-lg-9 px-md-4" tabindex="-1">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
