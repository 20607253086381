<header class="d-flex flex-wrap justify-content-center py-3 mb-4 bg-dark">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
          <span class="fs-4 text-white">Pet Project</span>
        </a>
      </div>
      <div class="col-md-8">
        <ul class="nav nav-pills d-flex justify-content-end">
          <li *ngFor="let item of mockData$ | async; let i = index" class="nav-item">
            <a [routerLink]="item.url" routerLinkActive="active" class="nav-link text-white">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
